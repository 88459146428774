
export const CustomerEnquiryConstant = {
    Table:{
        CUSTOMER_CODE: "Customer Code",
        COMPANY_CODE: "Company Code",
        TYPE: "Type",
        NTN_NO: "Customer NTN No",
        GST_NO: "Customer GST No",
        CUSTOMER_ADDRESS: "Customer Address",
        EMAIL_ADDRESS: "Email Address",
        CUSTOMER_NAME1: "Customer Name 1",
        CUSTOMER_NAME2: "Customer Name2",
        NIF_NUMBER: "N.I.F Number",
        PAYMENT_TERM: "Payment Term",
        INTEREST_RATE: "Interest Rate",
        PAYMENT_CUTOFF_DAY1: "Payment Cut Off Day 1",
        PAYMENT_CUTOFF_DAY2: "Payment Cut Off Day 2",
        PAYMENT_CUTOFF_DAY3: "Payment Cut Off Day 3",
        STATEMENT_ADDRESS1: "Statement Address 1",
        STATEMENT_ADDRESS2: "Statement Address 2",
        STATEMENT_ADDRESS3: "Statement Address 3",
        BILLING_ADDRESS1: "Billing Address 1",
        BILLING_ADDRESS2: "Billing Address 2",
        BILLING_ADDRESS3: "Billing Address 3",
        BILLING_ADDRESS4: "Billing Address 4",
        CITY: "City",
        COUNTY: "County",
        STATE: "State",
        PROVINCE: "Province",
        POSTAL_CODE: "Postal Code",
        COUNTRY: "Country",
        CUSTOMER_TEL: "Tel",
        CUSTOMER_FAX: "Fax",
        GL_ACCOUNT: "GL Account Code",
        CREDITLIMIT: "Credit Limit",
        OUTSTANDINGBALANCE: "Outstanding Balance",
        NETBALANCE: "Net Balance",
        UNFINALIZEDAMOUNT: "Un-Finalized Amount",
        CREDITLIMITEXCEEDED: "Credit Limit Exceeded",
        REGION: "Region",
        SHIPPING_LINE_ADDRESS1: "Shipping Line Address1",
        SHIPPING_LINE_ADDRESS2: "Shipping Line Address2",
        SHIPPING_LINE_ADDRESS3: "Shipping Line Address3",
        ACTIVE_IND: "Active Ind.",
    },
    Title:{
        TITLE: "eBis Customer",
    },
}