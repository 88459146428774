import { INITIAL_CUSTOMER_ENQUIRY_COL_DEF } from "presentation/constant/CustomerEnquiry/CustomerEnquiryColumnDefinition";
import { CustomerEnquiryConstant } from "presentation/constant/CustomerEnquiry/CustomerEnquiryConstant";
import { useCustomerEnquiryTracked } from "presentation/store/CustomerEnquiry/CustomerEnquiryProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useMemo, useRef } from "react";
const CustomerEnquiryTablePanel = () => {
    const gridRef: any = useRef(null);
    const [customerEnquiryState] = useCustomerEnquiryTracked();
    const { customerEnquiryList } = customerEnquiryState;

    const memoCustomerEnquiryTable = useMemo(() => {

        return (
            <NbisTable
                headerLabel={CustomerEnquiryConstant.Title.TITLE}
                id='customer-enquiry-table'
                isNewColumnSetting={true}
                columns={INITIAL_CUSTOMER_ENQUIRY_COL_DEF}
                data={customerEnquiryList ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                // onSelectionChanged={handleSelectionChange}
                // onRowDoubleClick={(e: any, entity: CustomerEnquiryEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [customerEnquiryList])

    return <><TableWrapper>
        {memoCustomerEnquiryTable}
    </TableWrapper>
    </>;
}

export default memo(CustomerEnquiryTablePanel);
