import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CustomerEnquiryConstant } from "./CustomerEnquiryConstant";

const CUSTOMER_ENQUIRY_CONSTANT = CustomerEnquiryConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_CUSTOMER_ENQUIRY_COL_DEF: any[] = [

    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CUSTOMER_CODE,
        field: "customerCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        // pinned: "left",
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.COMPANY_CODE,
        field: "companyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.TYPE,
        field: "type",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.NTN_NO,
        field: "ntnNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.GST_NO,
        field: "gstNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CUSTOMER_ADDRESS,
        field: "customerAddress",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.EMAIL_ADDRESS,
        field: "emailAddress",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CUSTOMER_NAME1,
        field: "customerDesc1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 300,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CUSTOMER_NAME2,
        field: "customerDesc2",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 300,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.NIF_NUMBER,
        field: "nif",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.PAYMENT_TERM,
        field: "paymentTerm",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 170,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.INTEREST_RATE,
        field: "interestRate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.PAYMENT_CUTOFF_DAY1,
        field: "paymentCutoffDay1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.PAYMENT_CUTOFF_DAY2,
        field: "paymentCutoffDay2",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.PAYMENT_CUTOFF_DAY3,
        field: "paymentCutoffDay3",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.STATEMENT_ADDRESS1,
        field: "statementAddress1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.STATEMENT_ADDRESS2,
        field: "statementAddress2",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.STATEMENT_ADDRESS3,
        field: "statementAddress1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.BILLING_ADDRESS1,
        field: "billingAddress1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.BILLING_ADDRESS2,
        field: "billingAddress2",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.BILLING_ADDRESS3,
        field: "billingAddress3",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.BILLING_ADDRESS4,
        field: "billingAddress4",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CITY,
        field: "city",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.COUNTY,
        field: "county",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.STATE,
        field: "state",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.PROVINCE,
        field: "province",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.POSTAL_CODE,
        field: "postalCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.COUNTRY,
        field: "country",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CUSTOMER_TEL,
        field: "telephoneNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CUSTOMER_FAX,
        field: "faxNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.GL_ACCOUNT,
        field: "glAccount",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CREDITLIMIT,
        field: "creditLimit",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.OUTSTANDINGBALANCE,
        field: "outstandingBal",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.NETBALANCE,
        field: "netBal",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.UNFINALIZEDAMOUNT,
        field: "unFinalizedAmount",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.CREDITLIMITEXCEEDED,
        field: "creditLimitExceeded",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.REGION,
        field: "region",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.SHIPPING_LINE_ADDRESS1,
        field: "shippingLineAddress1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.SHIPPING_LINE_ADDRESS2,
        field: "shippingLineAddress2",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.SHIPPING_LINE_ADDRESS3,
        field: "shippingLineAddress3",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: CUSTOMER_ENQUIRY_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}