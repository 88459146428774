import { EMPTY_CUSTOMER_ENQUIRY_ENTITY } from "domain/entity/CustomerEnquiry/CustomerEnquiryEntity";
import { CustomerEnquiryRepository } from "domain/repository/CustomerEnquiry/CustomerEnquiryRepo";
import { CustomerEnquiryModel } from "presentation/model/CustomerEnquiry/CustomerEnquiryModel";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface CustomerEnquiryVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CustomerEnquiryModel>> | ((value: SetStateAction<CustomerEnquiryModel>) => void),
    ],
    customerEnquiryRepo:CustomerEnquiryRepository,
}

export const CustomerEnquiryVM = ({dispatch, customerEnquiryRepo}:CustomerEnquiryVMProps) => {
    const [customerEnquiryDispatch] = dispatch;


    const searchAllCustomerEnquiryList = async() => {
        customerEnquiryDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_CUSTOMER_ENQUIRY_ENTITY},
                currentEditRow: {...EMPTY_CUSTOMER_ENQUIRY_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows:[],
                customerEnquiryList: [],
            }
        })
        customerEnquiryRepo.searchAllCustomerEnquiryList().then(data =>{
            if(data){
                customerEnquiryDispatch(prevState => {
                    return {
                        ...prevState,
                        customerEnquiryList: data,
                    }
                })
            }else{
                customerEnquiryDispatch(prevState => {
                    return {
                        ...prevState,
                        customerEnquiryList: [],
                    }
                })
            }
        })
    }







    return {
        searchAllCustomerEnquiryList: searchAllCustomerEnquiryList,
    }
}