import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CustomerEnquiryProvider } from "presentation/store/CustomerEnquiry/CustomerEnquiryProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import CustomerEnquiryMaintenance from "presentation/view/section/CustomerEnquiry/CustomerEnquiryMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CustomerEnquiryContMain = () => {
    return <ANAInfoWrapper permission={Permission.CUSTOMER_ENQUIRY_MAINTENANCE}>
        <MessageBarWrapper>
            <CustomerEnquiryProvider>
                <GridStyles/>
                <CustomerEnquiryMaintenance/>
            </CustomerEnquiryProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CustomerEnquiryContMain;