import { CustomerEnquiryEntity, EMPTY_CUSTOMER_ENQUIRY_ENTITY } from "domain/entity/CustomerEnquiry/CustomerEnquiryEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface CustomerEnquiryDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    opsLineDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    cntrSizeDropdownOptions: DropdownProps[],
    cntrTypeDropdownOptions: DropdownProps[],
    shipmentTypeDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
    marshalCodeDropdownOptions: DropdownProps[],
    terminalCodeDropdownOptions: DropdownProps[],
    marshallingTypeDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    specHandingIndDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
}
export interface CustomerEnquiryViewChangeState extends BaseViewChangeSate {
}

export interface CustomerEnquiryModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    dynamicOptions: CustomerEnquiryDropdownOptions,
    customerEnquiryList: CustomerEnquiryEntity[],
    selectedRows: CustomerEnquiryEntity[],
    updatedRows: CustomerEnquiryEntity[],
    currentRow: CustomerEnquiryEntity,
    currentEditRow: CustomerEnquiryEntity,
    
}

export const EMPTY_CUSTOMER_ENQUIRY_MODEL: CustomerEnquiryModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        opsLineDropdownOptions: [],
        companyCodeDropdownOptions: [],
        cntrSizeDropdownOptions: [],
        cntrTypeDropdownOptions: [],
        shipmentTypeDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        modalityDropdownOptions: [],
        marshalCodeDropdownOptions: [],
        terminalCodeDropdownOptions: [],
        marshallingTypeDropdownOptions: [],
        chargeIndDropdownOptions: [],
        specHandingIndDropdownOptions: [],
        operationTypeDropdownOptions: []
    },
    customerEnquiryList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: {...EMPTY_CUSTOMER_ENQUIRY_ENTITY},
    currentEditRow: {...EMPTY_CUSTOMER_ENQUIRY_ENTITY},
}