import { useCustomerEnquiryVM } from "presentation/hook/CustomerEnquiry/useCustomerEnquiryVM";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CustomerEnquiryTablePanel from "./Table/CustomerEnquiryTablePanel";


const CustomerEnquiryMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const customerEnquiryVM = useCustomerEnquiryVM();


    useEffect(() => {
        const initialScreen = async() => {   
            try {     
                const results = await Promise.allSettled([
                    customerEnquiryVM.searchAllCustomerEnquiryList(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                    setIsLoading(false);
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen();
    }, [customerEnquiryVM])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
        
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"100%"}
                rightSectionWidth={"0%"}
                leftChildren={<CustomerEnquiryTablePanel/>}
                />
        </div>
    </>
}

export default memo(CustomerEnquiryMaintenance);