import { CustomerEnquiryRepoImpl } from "domain/repository/CustomerEnquiry/CustomerEnquiryRepoImpl";
import { useCustomerEnquiryTracked } from "presentation/store/CustomerEnquiry/CustomerEnquiryProvider";
import { CustomerEnquiryVM } from "presentation/viewModel/CustomerEnquiry/CustomerEnquiryVM";
import { useMemo } from "react";

export const useCustomerEnquiryVM = () => {
    const [, setCustomerEnquiryState] = useCustomerEnquiryTracked();
    const customerEnquiryVM = useMemo(() =>
        CustomerEnquiryVM({
            dispatch: [setCustomerEnquiryState],
            customerEnquiryRepo: CustomerEnquiryRepoImpl(),
        }), [setCustomerEnquiryState])

    return customerEnquiryVM
}