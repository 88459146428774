import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CustomerEnquiryEntity } from "domain/entity/CustomerEnquiry/CustomerEnquiryEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CustomerEnquiryRepository } from "./CustomerEnquiryRepo";

export const CustomerEnquiryRepoImpl = (): CustomerEnquiryRepository => {
    
    const searchUrl = "/v1/searchAllCustomerEnquiryList"

    const searchAllCustomerEnquiryList = async() : Promise<CustomerEnquiryEntity[]> => {
        
        return axiosGetData(companyAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: CustomerEnquiryEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/customerEnquiryApply"
        return await axiosPostData(companyAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: CustomerEnquiryEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/customerEnquirySave"
        return await axiosPostData(companyAxiosInstance, `${saveUrl}`, row);
    }

    const uploadData =async(csvData: CustomerEnquiryEntity[]): Promise<ResponseEntity> => {
        const uploadUrl = "/v1/customerEnquiryUpload"
        return await axiosPostData(companyAxiosInstance, `${uploadUrl}`, csvData);
    }

    return {
        uploadData: uploadData,
        onSave: onSave,
        onApply: onApply,
        searchAllCustomerEnquiryList : searchAllCustomerEnquiryList,
    }
}
