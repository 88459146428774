export interface CustomerEnquiryEntity{

    id?: number | null,
    activeInd?: string | null,
    customerCode?: string | null,
    companyCode?: string | null,
    type?: string | null,
    ntnNo?: string | null,
    gstNo?: string | null,
    customerAddress?: string | null,
    emailAddress?: string | null,
    customerDesc1?: string | null,
    customerDesc2?: string | null,
    nif?: string | null,
    paymentTerm?: string | null,
    interestRate?: string | null,
    paymentCutoffDay1?: string | null,
    paymentCutoffDay2?: string | null,
    paymentCutoffDay3?: string | null,
    statementAddress1?: string | null,
    statementAddress2?: string | null,
    statementAddress3?: string | null,
    billingAddress1?: string | null,
    billingAddress2?: string | null,
    billingAddress3?: string | null,
    billingAddress4?: string | null,
    city?: string | null,
    county?: string | null,
    state?: string | null,
    province?: string | null,
    postalCode?: string | null,
    country?: string | null,
    telephoneNo?: string | null,
    faxNo?: string | null,
    glAccount?: string | null,
    creditLimit?: string | null,
    outstandingBal?: string | null,
    netBal?: string | null,
    unFinalizedAmount?: string | null,
    creditLimitExceeded?: string | null,
    region?: string | null,
    shippingLineAddress1?: string | null,
    shippingLineAddress2?: string | null,
    shippingLineAddress3?: string | null,

    [key: string]: string | boolean | number | null | Object | undefined
}

export const EMPTY_CUSTOMER_ENQUIRY_ENTITY : CustomerEnquiryEntity = {

    id: 0,
    activeInd: "",
    customerCode: "",
    companyCode: "",
    type: "",
    ntnNo: "",
    gstNo: "",
    customerAddress: "",
    emailAddress: "",
    customerDesc1: "",
    customerDesc2: "",
    nif: "",
    paymentTerm: "",
    interestRate: "",
    paymentCutoffDay1: "",
    paymentCutoffDay2: "",
    paymentCutoffDay3: "",
    statementAddress1: "",
    statementAddress2: "",
    statementAddress3: "",
    billingAddress1: "",
    billingAddress2: "",
    billingAddress3: "",
    billingAddress4: "",
    city: "",
    county: "",
    state: "",
    province: "",
    postalCode: "",
    country: "",
    telephoneNo: "",
    faxNo: "",
    glAccount: "",
    creditLimit: "",
    outstandingBal: "",
    netBal: "",
    unFinalizedAmount: "",
    creditLimitExceeded: "",
    region: "",
    shippingLineAddress1: "",
    shippingLineAddress2: "",
    shippingLineAddress3: "",

}
